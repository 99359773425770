
export const countryCodes = [
  { code: '+1', name: 'United States' },
  { code: '+86', name: 'China' },
  { code: '+91', name: 'India' },
  { code: '+7', name: 'Russia' },
  { code: '+81', name: 'Japan' },
  { code: '+49', name: 'Germany' },
  { code: '+44', name: 'United Kingdom' },
  { code: '+33', name: 'France' },
  { code: '+39', name: 'Italy' },
  { code: '+55', name: 'Brazil' },
  { code: '+61', name: 'Australia' },
  { code: '+82', name: 'South Korea' },
  { code: '+34', name: 'Spain' },
  { code: '+1-613', name: 'Canada' },
  { code: '+90', name: 'Turkey' },
  { code: '+31', name: 'Netherlands' },
  { code: '+27', name: 'South Africa' },
  { code: '+54', name: 'Argentina' },
  { code: '+60', name: 'Malaysia' },
  { code: '+65', name: 'Singapore' },
  { code: '+66', name: 'Thailand' },
  { code: '+62', name: 'Indonesia' },
  { code: '+63', name: 'Philippines' },
  { code: '+52', name: 'Mexico' },
  { code: '+32', name: 'Belgium' },
  { code: '+47', name: 'Norway' },
  { code: '+46', name: 'Sweden' },
  { code: '+41', name: 'Switzerland' },
  { code: '+48', name: 'Poland' },
  { code: '+30', name: 'Greece' },
  { code: '+45', name: 'Denmark' },
  { code: '+351', name: 'Portugal' },
  { code: '+43', name: 'Austria' },
  { code: '+420', name: 'Czech Republic' },
  { code: '+36', name: 'Hungary' },
  { code: '+358', name: 'Finland' },
  { code: '+40', name: 'Romania' },
  { code: '+20', name: 'Egypt' },
  { code: '+234', name: 'Nigeria' },
  { code: '+256', name: 'Uganda' },
  { code: '+254', name: 'Kenya' },
  { code: '+212', name: 'Morocco' },
  { code: '+213', name: 'Algeria' },
  { code: '+216', name: 'Tunisia' },
  { code: '+220', name: 'Gambia' },
  { code: '+221', name: 'Senegal' },
  { code: '+225', name: 'Ivory Coast' },
  { code: '+233', name: 'Ghana' },
  { code: '+240', name: 'Equatorial Guinea' },
  { code: '+241', name: 'Gabon' },
  { code: '+250', name: 'Rwanda' },
  { code: '+251', name: 'Ethiopia' },
  { code: '+255', name: 'Tanzania' },
  { code: '+260', name: 'Zambia' },
  { code: '+263', name: 'Zimbabwe' },
  { code: '+57', name: 'Colombia' },
  { code: '+58', name: 'Venezuela' },
  { code: '+51', name: 'Peru' },
  { code: '+507', name: 'Panama' },
  { code: '+591', name: 'Bolivia' },
  { code: '+593', name: 'Ecuador' },
  { code: '+595', name: 'Paraguay' },
  { code: '+598', name: 'Uruguay' },
  { code: '+84', name: 'Vietnam' },
  { code: '+95', name: 'Myanmar' },
  { code: '+855', name: 'Cambodia' },
  { code: '+856', name: 'Laos' },
  { code: '+92', name: 'Pakistan' },
  { code: '+977', name: 'Nepal' },
  { code: '+94', name: 'Sri Lanka' },
  { code: '+960', name: 'Maldives' },
  { code: '+975', name: 'Bhutan' },
  { code: '+64', name: 'New Zealand' },
  { code: '+679', name: 'Fiji' },
  { code: '+682', name: 'Cook Islands' },
  { code: '+685', name: 'Samoa' },
  { code: '+691', name: 'Micronesia' },
  { code: '+692', name: 'Marshall Islands' },
  { code: '+93', name: 'Afghanistan' },
  { code: '+355', name: 'Albania' },
  { code: '+374', name: 'Armenia' },
  { code: '+994', name: 'Azerbaijan' },
  { code: '+973', name: 'Bahrain' },
  { code: '+880', name: 'Bangladesh' },
  { code: '+226', name: 'Burkina Faso' },
  { code: '+237', name: 'Cameroon' },
  { code: '+238', name: 'Cape Verde' },
  { code: '+236', name: 'Central African Republic' },
  { code: '+56', name: 'Chile' },
  { code: '+242', name: 'Congo' },
  { code: '+', name: 'Other' }
];

export const blogs = [
  {
    "id": "66821a0781de0d1f404a1f5c",
    "title": "Understanding Consensus Mechanisms in Blockchain Technology",
    "blogger": "Admin",
    "description": "Consensus mechanisms are fundamental to blockchain technology, ensuring agreement and trust among participants in a decentralized network without relying on a central authority, Consensus mechanisms in blockchain enable nodes to validate and agree on the state of the ledger through various protocols. These include Proof of Work (PoW), where computational puzzles validate transactions (Bitcoin), Proof of Stake (PoS), where validators are chosen based on their stake (Ethereum 2.0), and other innovative methods like Delegated Proof of Stake (DPoS) and Practical Byzantine Fault Tolerance (PBFT). Each mechanism balances security, decentralization, scalability, and energy efficiency differently, catering to diverse blockchain applications from cryptocurrencies to enterprise solutions. Understanding these mechanisms is crucial for grasping blockchain's reliability and operational dynamics\"",
    "image": "public/uploads/blogs/image-1719802374943.png",
    "createdAt": "2024-07-01T02:52:55.083Z"
  },
  {
    "id": "66821bd94aa7ea65bd654b5b",
    "title": "Understanding Consensus Mechanisms in Blockchain Technology",
    "blogger": "Admin",
    "description": "Consensus mechanisms are fundamental to blockchain technology, ensuring agreement and trust among participants in a decentralized network without relying on a central authority, Consensus mechanisms in blockchain enable nodes to validate and agree on the state of the ledger through various protocols. These include Proof of Work (PoW), where computational puzzles validate transactions (Bitcoin), Proof of Stake (PoS), where validators are chosen based on their stake (Ethereum 2.0), and other innovative methods like Delegated Proof of Stake (DPoS) and Practical Byzantine Fault Tolerance (PBFT). Each mechanism balances security, decentralization, scalability, and energy efficiency differently, catering to diverse blockchain applications from cryptocurrencies to enterprise solutions. Understanding these mechanisms is crucial for grasping blockchain's reliability and operational dynamics\"",
    "image": "public/uploads/blogs/image-1719802840317.png",
    "createdAt": "2024-07-01T03:00:41.220Z"
  },
  {
    "id": "66821bd94aa7ea65bd654b5b",
    "title": "Understanding Consensus Mechanisms in Blockchain Technology",
    "blogger": "Admin",
    "description": "Consensus mechanisms are fundamental to blockchain technology, ensuring agreement and trust among participants in a decentralized network without relying on a central authority, Consensus mechanisms in blockchain enable nodes to validate and agree on the state of the ledger through various protocols. These include Proof of Work (PoW), where computational puzzles validate transactions (Bitcoin), Proof of Stake (PoS), where validators are chosen based on their stake (Ethereum 2.0), and other innovative methods like Delegated Proof of Stake (DPoS) and Practical Byzantine Fault Tolerance (PBFT). Each mechanism balances security, decentralization, scalability, and energy efficiency differently, catering to diverse blockchain applications from cryptocurrencies to enterprise solutions. Understanding these mechanisms is crucial for grasping blockchain's reliability and operational dynamics\"",
    "image": "public/uploads/blogs/image-1719802840317.png",
    "createdAt": "2024-07-01T03:00:41.220Z"
  },
  {
    "id": "66821bd94aa7ea65bd654b5b",
    "title": "Understanding Consensus Mechanisms in Blockchain Technology",
    "blogger": "Admin",
    "description": "Consensus mechanisms are fundamental to blockchain technology, ensuring agreement and trust among participants in a decentralized network without relying on a central authority, Consensus mechanisms in blockchain enable nodes to validate and agree on the state of the ledger through various protocols. These include Proof of Work (PoW), where computational puzzles validate transactions (Bitcoin), Proof of Stake (PoS), where validators are chosen based on their stake (Ethereum 2.0), and other innovative methods like Delegated Proof of Stake (DPoS) and Practical Byzantine Fault Tolerance (PBFT). Each mechanism balances security, decentralization, scalability, and energy efficiency differently, catering to diverse blockchain applications from cryptocurrencies to enterprise solutions. Understanding these mechanisms is crucial for grasping blockchain's reliability and operational dynamics\"",
    "image": "public/uploads/blogs/image-1719802840317.png",
    "createdAt": "2024-07-01T03:00:41.220Z"
  },
  {
    "id": "66821bd94aa7ea65bd654b5b",
    "title": "Understanding Consensus Mechanisms in Blockchain Technology",
    "blogger": "Admin",
    "description": "Consensus mechanisms are fundamental to blockchain technology, ensuring agreement and trust among participants in a decentralized network without relying on a central authority, Consensus mechanisms in blockchain enable nodes to validate and agree on the state of the ledger through various protocols. These include Proof of Work (PoW), where computational puzzles validate transactions (Bitcoin), Proof of Stake (PoS), where validators are chosen based on their stake (Ethereum 2.0), and other innovative methods like Delegated Proof of Stake (DPoS) and Practical Byzantine Fault Tolerance (PBFT). Each mechanism balances security, decentralization, scalability, and energy efficiency differently, catering to diverse blockchain applications from cryptocurrencies to enterprise solutions. Understanding these mechanisms is crucial for grasping blockchain's reliability and operational dynamics\"",
    "image": "public/uploads/blogs/image-1719802840317.png",
    "createdAt": "2024-07-01T03:00:41.220Z"
  }
]

export const testimonials = [
  {
    "id": "66821f31e0c1c535c23763ad",
    "clientName": "Emily Reb",
    "massage": "Your ability to understand complex systems and translate requirements into effective solutions makes you an invaluable client. Your dedication to building robust, scalable, and efficient applications showcases your commitment to excellence",
    "image": "public/uploads/testimonials/image-1719803697168.jpeg",
    "createdAt": "2024-07-01T03:14:57.328Z"
  },
  {
    "id": "66821f33e0c1c535c23763ae",
    "clientName": "Emily Reb",
    "massage": "Your ability to understand complex systems and translate requirements into effective solutions makes you an invaluable client. Your dedication to building robust, scalable, and efficient applications showcases your commitment to excellence",
    "image": "public/uploads/testimonials/image-1719803699743.jpeg",
    "createdAt": "2024-07-01T03:14:59.888Z"
  },
  {
    "id": "66821f35e0c1c535c23763af",
    "clientName": "Emily Reb",
    "massage": "Your ability to understand complex systems and translate requirements into effective solutions makes you an invaluable client. Your dedication to building robust, scalable, and efficient applications showcases your commitment to excellence",
    "image": "public/uploads/testimonials/image-1719803701642.jpeg",
    "createdAt": "2024-07-01T03:15:01.809Z"
  },
  {
    "id": "66821f37e0c1c535c23763b0",
    "clientName": "Emily Reb",
    "massage": "Your ability to understand complex systems and translate requirements into effective solutions makes you an invaluable client. Your dedication to building robust, scalable, and efficient applications showcases your commitment to excellence",
    "image": "public/uploads/testimonials/image-1719803703038.jpeg",
    "createdAt": "2024-07-01T03:15:03.168Z"
  },
  {
    "id": "66821f38e0c1c535c23763b1",
    "clientName": "Emily Reb",
    "massage": "Your ability to understand complex systems and translate requirements into effective solutions makes you an invaluable client. Your dedication to building robust, scalable, and efficient applications showcases your commitment to excellence",
    "image": "public/uploads/testimonials/image-1719803704312.jpeg",
    "createdAt": "2024-07-01T03:15:04.449Z"
  }
]
